<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        isEditing
          ? `Editer le partenaire : ${partner.name}`
          : "Créer un nouveau partenaire"
      }}
      <span class="text-xs" v-if="!isEditing"
        >(interface désactivée : action à faire par les devs)</span
      >
    </p>
    <v-card class="pa-4">
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="FormPartner">
            <v-row>
              <v-col cols="12" sm="12" v-if="isEditing">
                <v-text-field
                  v-model="partner.id"
                  label="Id"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="partner.name"
                  label="Nom"
                  outlined
                  dense
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="partner.code"
                  label="Code"
                  outlined
                  dense
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="partner.cdg_name"
                  label="Nom CDG"
                  outlined
                  dense
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  dense
                  v-model="partner.currency_id"
                  :items="currencyList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Devise"
                  item-text="name"
                  item-value="id"
                  :rules="[currencySelected]"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  dense
                  multiple
                  v-model="partner.nature_ids"
                  :items="natureList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Natures"
                  item-text="name"
                  item-value="id"
                  :rules="[required]"
                  disabled
                ></v-select>
                <span class="text-xs"
                  >Le champ "Natures" est indicatif : la mise à jour se fait à
                  travers les <strong>Vues de partenaires</strong>.</span
                >
              </v-col>

              <v-col cols="12" sm="12">
                <v-switch
                  v-model="partner.active"
                  :label="`Actif: ${partner.active.toString()}`"
                  outlined
                  dense
                ></v-switch>
              </v-col>

              <v-col cols="12">
                <v-btn
                  v-if="isEditing"
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="savePartner"
                >
                  Enregistrer</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="goToList"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import omit from "lodash/omit";

import { required } from "@core/utils/validation";
import { NOTIF_DISPLAY_DURATION } from "@/utils/constants";

export default {
  name: "PartnerEdit",
  data() {
    return {
      required,
      currencySelected: (v) => {
        return !!v;
      },
      partner: {
        id: 0,
        name: "",
        code: "",
        cdg_name: "",
        active: false,
        currency_id: 0,
        nature_ids: [],
      },
    };
  },
  async created() {
    if ("id" in this.$route.params && this.$route.params.id) {
      const { data } = await axios.get(
        `/partners/partner/${this.$route.params.id}`
      );
      this.partner = {
        ...data,
        nature_ids: (data.nature_id_list ?? "")
          .split(",")
          .map((nature_id) => parseInt(nature_id)),
      };
    }

    this.$store.dispatch("admin/getPartnerCurrencyList");
    this.$store.dispatch("admin/getPartnerNatureList");
  },
  methods: {
    async savePartner() {
      if (!this.isEditing) {
        return;
      }

      const isFormValid = this.$refs.FormPartner.validate();
      if (!isFormValid) {
        return;
      }

      const payload = {
        ...omit(this.partner, ["nature_ids"]),
      };

      if (this.isEditing) {
        await axios.put(`/partners/partner/${this.partner.id}`, payload);
      }

      await this.$store.dispatch("app/setSnackBar", {
        show: true,
        text: `Le partenaire ${this.name} a été ${
          this.isEditing ? "modifié" : "créé"
        }.`,
        color: "success",
        timeout: NOTIF_DISPLAY_DURATION,
      });

      setTimeout(() => this.goToList(), NOTIF_DISPLAY_DURATION);
    },
    goToList() {
      this.$router.push({ name: "admin-partners" });
    },
  },
  computed: {
    isEditing() {
      return this.partner.id > 0;
    },
    currencyList() {
      return this.$store.getters["admin/getPartnerCurrencyList"];
    },
    natureList() {
      return this.$store.getters["admin/getPartnerNatureList"];
    },
  },
};
</script>
